<template>
  <v-container class="my-0 my-md-16 py-0  px-0 px-md-3 pb-md-16">
    <v-row class="no-gutters">
      <v-col
        xl="8"
        offset-xl="2"
        md="10"
        offset-md="1"
        sm="12"
        offset-sm="0"
        class="py-0"
      >
        <v-card
          :class="
            $vuetify.breakpoint.smAndDown ? 'elevation-0' : 'elevation-24'
          "
          style="border-radius:6px"
        >
          <v-toolbar
            flat
            color="primary"
            height="120px"
            dark
            :class="
              $vuetify.breakpoint.smAndDown
                ? 'rounded-t-0'
                : 'rounded-t-lg pl-3'
            "
          >
            <v-toolbar-title class="d-flex align-center">
              <v-icon large>
                mdil-clock
              </v-icon>
              <span class="pl-6 text-h4">{{ $t("zamanlama") }}</span>
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text
            :class="$vuetify.breakpoint.smAndDown ? 'mb-16 pb-16' : ''"
          >
            <v-container fluid>
              <div v-for="(zaman, index) in zamanlama" :key="index">
                <v-row>
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <div class="text-body-1 font-weight-bold">
                      {{ zaman.gun }}
                    </div>
                  </v-col>
                  <v-col cols="12" md="4" class="d-flex align-center">
                    <v-radio-group
                      v-model="zaman.tip"
                      hide-details
                      class="ma-0 pa-0"
                    >
                      <v-radio :label="$t('aralik')" :value="1" />
                      <v-radio :label="$t('24Saat')" :value="2" />
                      <v-radio :label="$t('kapali')" :value="3" />
                    </v-radio-group>
                  </v-col>
                  <v-col
                    v-if="zaman.tip == 1"
                    cols="12"
                    md="4"
                    class="d-flex align-center my-6"
                  >
                    <v-text-field
                      v-model="zaman.acilis"
                      :label="$t('acilis')"
                      class="ma-0 pa-0 pr-4"
                      type="time"
                      hide-details
                    />

                    <v-text-field
                      v-model="zaman.kapanis"
                      :label="$t('kapanis')"
                      class="ma-0 pa-0"
                      type="time"
                      hide-details
                    />
                  </v-col>
                </v-row>
                <hr
                  v-if="index < zamanlama.length - 1"
                  class="my-8"
                  color="grey"
                  style="opacity:0.1"
                />
              </div>
            </v-container>
          </v-card-text>
          <div :class="$vuetify.breakpoint.smAndDown ? 'fixedCardActions' : ''">
            <v-divider />
            <v-card-actions
              class=" pa-0"
              :class="$vuetify.breakpoint.smAndDown ? 'white' : 'primary'"
            >
              <v-spacer />
              <v-btn
                block
                large
                :color="$vuetify.breakpoint.smAndDown ? 'primary' : 'white'"
                text
                class="font-weight-bold text-capitalize"
                @click="save()"
              >
                {{ $t("kaydet") }}
              </v-btn>
            </v-card-actions>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbar"
      multi-line
      timeout="2000"
      absolute
      centered
      color="primary"
      elevation="24"
      rounded="pill"
    >
      <div class="text-center">
        {{ snackbarText }}
      </div>
    </v-snackbar>
    <v-overlay :value="snackbar" :z-index="0" />
  </v-container>
</template>
<script>
import { veri, depo } from "./../db";

export default {
  name: "ZamanlamaView",
  data: () => ({
    zamanlama: [],
    snackbar: false,
    snackbarText: ""
  }),
  firebase: {
    zamanlama: veri.ref("zamanlama")
  },
  computed: {},

  watch: {},

  methods: {
    save() {
      veri
        .ref("zamanlama/")
        .set(this.zamanlama)
        .then(() => {
          this.snackbar = true;
          this.snackbarText = this.$t("veriBasarili");
        });
    }
  }
};
</script>
<style lang="scss">
.fixedCardActions {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  border-radius: unset !important;
}
</style>
